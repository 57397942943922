import React from 'react'
import d from './dd.svg'
import u from './pinks.png'
import x from './tx.png'
import t from './telegr.png'
import ets from './ets.png'
import medi from './gitb.png'
import { Grid } from '@mui/material'
function Desktoptwo() {
  return (
    <div className='container'>

                    <Grid item md={4} lg={4} xs={12} sm={12} className='centeralldiv'>

                    <ul class="wrapper">
                      <a href='https://t.me/ilumeth'>  <li class="icon facebook">

<span> <img src={t} style={{width:"25px", position:"relative"  , top:"-3px"}}/></span>
</li></a>
<a href='https://twitter.com/ILUMeth'>  <li class="icon twitter">
 
 <span> <img src={x} style={{width:"25px", position:"relative"  , top:"-3px"}}/></span>
</li></a>
<a href='https://etherscan.io/address/0x98e1f56b334438e3f0bde22d92f5bfd746e0631f'>  <li class="icon twitter">
 
 <span> <img src={ets} style={{width:"25px", position:"relative"  , top:"-3px"}}/></span>
</li></a>

<a href='https://www.dextools.io/app/en/ether/pair-explorer/0x90da5958f67857c30069301278fb7e5b70d74aa5'> <li class="icon twitter">

<span> <img src={d} style={{width:"25px" , position:"relative"  , top:"-1px"}}/></span>
</li></a>




<a href='https://ilum.gitbook.io/untitled/'><li class="icon twitter">

<span> <img src={medi} style={{width:"30px" , position:"relative"  , top:"-1px"}}/></span>
</li>  </a>

</ul>
                    </Grid>

                   

    </div>
  )
}

export default Desktoptwo