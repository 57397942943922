
import { useRef } from "react";

import { Grid } from '@mui/material'
import './cloud.css'
import spil from './ilu.png'
import chart from './pgft.png'
import Roadmap from "./Roadmap";

import Uniswap from './Uniswap'


import p1 from './p1.png'
import p2 from './p2.png'
import p3 from './p3.png'
import p4 from './p4.png'
import p5 from './abs.png'
import p6 from './p6.png'




import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
function Desktop() {

  const textRef = useRef();

   // Check if the target date is stored in local storage
   const storedTargetDate = localStorage.getItem('targetDate');
   const targetDate = storedTargetDate ? new Date(storedTargetDate) : new Date('2023-10-12T23:00:00Z');
 
   // Save the target date to local storage
   localStorage.setItem('targetDate', targetDate.toISOString());
  return (
    <div className='container'>
  

      <br/>
      <Grid container spacing={2}>
         <Grid item md={12} lg={12} xs={12} sm={12}  className='CENTERALLDIV'>

<br/><br/><br/>

<div sx={{ maxWidth: '100%' }} className='backofcard'>
         <div class="glassy-bg"></div>
         
         
         
         </div>   <br/>
        <br/>  
<div className='smoke CENTERALLDIV'>
  

       <img src={spil} style={{width:"70%"}}   />
       
        </div>
    
        <br/>
        <br/>
        <br/>
        <br/>

        <div className=' CENTERALLDIVSS'>
  
<a href="https://mint.ilumeth.org/" style={{textDecoration:"none"}}>   

 <button className="button-86 " role="button" style={{textDecoration:"none"}}>MINT NFT</button> 
 
 
  </a>
     
       
        </div>

       
         
     
      
        <br/>
        <br/>   <br/>


      


      
         </Grid>
      
    
      </Grid>   <br/>   <br/>
<br/> 
<br/>  <br/>

<Grid container spacing={2}>
         <Grid item md={12} lg={12} xs={12} sm={12}  className='CENTERALLDIV'>
         <div className='smoke CENTERALLDIV'><ul>
            <li>T</li>
            <li>O</li>
            <li>K</li>
            <li>E</li>
            <li>N</li>
            <li>O</li>
            <li>M</li>
            <li>I</li>
            <li>C</li>
            <li>S</li>
        </ul>
        
        
        </div>

        <br/><br/>
         </Grid>

         <Grid item md={12} lg={12} xs={12} sm={12} className='CENTERALLDIV'>
         <div sx={{ maxWidth: '100%' }} className='backofcard'>
         <div class="glassy-bg"></div>
  <div class="content">
  <CardContent>
      <img src={chart} style={{width:"60%"   }}/>
      </CardContent>
  </div>
  
     
    </div>


         </Grid>
      </Grid>


      <br/> 

<br/>  <br/>
         <div className='smoke'><ul>
            <li>A</li>
            <li>b</li>
            <li>o</li>
            <li>u</li>
            <li>t</li>
            <li>U</li>
            <li>s</li>
          
        </ul>
        
        
        </div>

        <br/><br/>

      <Grid container spacing={0}>
      

         <Grid item md={12} lg={12} xs={12} sm={12} className=''>
        <br/>
         <div sx={{ maxWidth: '100%' }} className='backofcards'>
         <div class="glassy-bgw"></div>
  <div class="content">
  <CardContent >
  <br/>
      <p className='color_of_text'>Welcome to the enigmatic world of Illuminati Token ($ILUM)!</p>
      <br/>
      <p className='color_of_text'>At Illuminati, we are not your typical cryptocurrency project. We've taken inspiration from the mysterious and intriguing concept of the Illuminati to create a token that symbolizes unity, enlightenment, and empowerment within the decentralized financial realm. </p>

      </CardContent>
  </div>
  
     
    </div>


         </Grid>

       
    
      </Grid>
      <br/> 
<br/>  <br/>
         <div className='smoke'><ul>
            <li>Our Vision</li>
        
          
        </ul>
        
        
        </div>

        <br/><br/>

      <Grid container spacing={0}>
      
         <Grid item md={12} lg={12} xs={12} sm={12} className=''>
        <br/>
         <div sx={{ maxWidth: '100%' }} className='backofcards'>
         <div class="glassy-bgw"></div>
  <div class="content">
  <CardContent >

      <p className='color_of_text'>Our vision is hidden within the symbolism of the all-seeing eye, a symbol often associated with the Illuminati. We envision a world where financial power is decentralized and accessible to all, where individuals are in control of their financial destinies, and where unity and enlightenment prevail. </p>
      <br/>
     

      </CardContent>
  </div>
  
     
    </div>


         </Grid>

      
    
      </Grid>



      <br/> 
<br/>  <br/>
         <div className='smoke'><ul>
            <li>Join Us in the Quest</li>
        
          
        </ul>
        
        
        </div>

        <br/><br/>

      <Grid container spacing={0}>
     
         <Grid item md={12} lg={12} xs={12} sm={12} className=''>
        <br/>
         <div sx={{ maxWidth: '100%' }} className='backofcards'>
         <div class="glassy-bgw"></div>
  <div class="content">
  <CardContent >

      <p className='color_of_text'>





      We invite you to embark on this mysterious journey with us, where the pursuit of financial enlightenment is paramount. Become a part of Illuminati Token ($ILUM) and discover a new world of possibilities.
      <br/>   <br/>
Embrace the enigma, unlock the secrets, and together, let's redefine the future of decentralized finance.
<br/>   <br/>
Enter the world of Illuminati Token - $ILUM.


















      </p>
   
     

      </CardContent>
  </div>
  
     
    </div>


         </Grid>
 
    
      </Grid>

      <br/> 
<br/>  <br/>
         <div className='smoke'><ul>
            <li>  Roadmap</li>
        
          
        </ul>
        
        
        </div>

        <br/><br/>


        <Grid container spacing={0} className="CENTERALLDIV">
          <Roadmap/>
  </Grid>
    


      <br/> 
<br/>  <br/>
         <div className='smoke'><ul>
            <li>Providers</li>
        
          
        </ul>
        
        
        </div>

        <br/><br/>

      <Grid container spacing={0}>
     
         <Grid item md={4} lg={4} xs={6} sm={6} className='CENTERALLDIV'>
         <br/>
<img src={p3} style={{width:"50%"   , borderRadius:"10px" , position:"relative" , top:"40px" }}/>



</Grid>
          
<Grid item md={4} lg={4} xs={6} sm={6} className='CENTERALLDIV'>
         <br/>
<img src={p1} style={{width:"30%"   , borderRadius:"10px" }}/>



</Grid>
        
<Grid item md={4} lg={4} xs={6} sm={6} className='CENTERALLDIV'>
         <br/>
<img src={p2} style={{width:"30%"   , borderRadius:"10px" }}/>



</Grid>






        
<Grid item md={4} lg={4} xs={6} sm={6} className='CENTERALLDIV'>
         <br/>
<img src={p5} style={{width:"55%"   , borderRadius:"10px" , position:"relative" , top:"40px"  }}/>



</Grid>
        
<Grid item md={4} lg={4} xs={6} sm={6} className='CENTERALLDIV'>
         <br/>
<img src={p4} style={{width:"55%"   , borderRadius:"10px" , position:"relative" , top:"40px"  }}/>



</Grid>

        
<Grid item md={4} lg={4} xs={6} sm={6} className='CENTERALLDIV'>
         <br/>
<img src={p6} style={{width:"50%"   , borderRadius:"10px" , position:"relative" , top:"40px"  }}/>



</Grid>
      </Grid>




      <br/><br/> <br/><br/> <br/><br/>
    

      <Grid container spacing={0}>
    

        <br/><br/>
         <Grid item md={12} lg={12} xs={12} sm={12} className=''>
        <br/>
         <div className=''>
      
  <div >
  < >

      <p className='color_of_textd'>

      Disclaimer <br/>
The content on this website, including information about Illuminati Token ($ILUM), is for informational purposes only and is not financial advice. Cryptocurrency investments carry risks, and users should conduct their research and consult with financial professionals before making any investment decisions.


















      </p>
   
     

      </>
  </div>
  
     
    </div>


         </Grid>
         <br/><br/>
      
         <Grid item md={12} lg={12} xs={12} sm={12} className=''>
        <br/>
         <div className=''>
      
  <div >
  < >

    
   
     

      </>
  </div>
  
     
    </div>


         </Grid>
      </Grid>


      <div class="clouds">
  <div class="clouds-1"></div>
  <div class="clouds-2"></div>
  <div class="clouds-3"></div>
</div>

    </div>
  )
}

export default Desktop