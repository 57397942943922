import React from 'react'
import { Grid } from '@mui/material'


function Uniswap() {
  return (

    
    <div >
        <Grid container spacing={0}  >
        <Grid item md={3} lg={3} xs={12} sm={12}>


</Grid>  

<Grid item md={6} lg={6} xs={12} sm={12}>

         <div className="iframe-container ">
   <iframe
      src="https://app.uniswap.org/add/v2/0x98e1f56b334438e3f0bde22d92f5bfd746e0631f/ETH"
      height="660px"
      width="100%"
      className='centerAll'
      style={{
       
        maxWidth: '600px',
        minWidth: '300px',
      }}
    />
    </div>

         </Grid>
         <Grid item md={3} lg={3} xs={12} sm={12}>


</Grid>

        </Grid>
   

    </div>
  )
}

export default Uniswap