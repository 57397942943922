import React from 'react'
import { Grid } from '@mui/material'
import mint from './mint.gif'


function MintComponent() {
  return (
    <div>
    <br/>
    <br/>
    <div className=' CENTERALLDIVSS'>  
<button className="button-86 " role="button" style={{textDecoration:"none"}}> Wallet</button> 
    </div>
    <br/>
    <br/>  
    <br/><br/>
<div className='container'>

    <Grid  container spacing={2}>

    <Grid item md={6} lg={6} xs={12} sm={12} className=' CENTERALLDIVSS'>
    <img src={mint} style={{width:"80%" , borderRadius:"10px"}} />

      
</Grid>



<Grid item md={6} lg={6} xs={12} sm={12}>

<br/>
    <br/>    <br/>    <br/>
    <div className=' CENTERALLDIVSS'>  

   <p className='mint_quantity'>  0 / 999</p>


    </div>
    <br/>
    <br/>  

    <div className=' CENTERALLDIVSS'>  



<button className="button-86 " role="button" style={{textDecoration:"none"}}> Mint </button> 
    </div>
    <br/>
    <br/>  
      
</Grid>
    </Grid>

</div>














<Grid container spacing={0}>
    

    <br/><br/>
     <Grid item md={12} lg={12} xs={12} sm={12} className=''>
    <br/>
     <div className=''>
  
<div >
< >
<br/><br/>     <br/><br/>     <br/><br/>
  <p className='color_of_textd'>

  Disclaimer <br/>
The content on this website, including information about Illuminati Token ($ILUM), is for informational purposes only and is not financial advice. Cryptocurrency investments carry risks, and users should conduct their research and consult with financial professionals before making any investment decisions.


















  </p>

 

  </>
</div>

 
</div>


     </Grid>
     <br/><br/>
  
     <Grid item md={12} lg={12} xs={12} sm={12} className=''>
    <br/>
     <div className=''>
  
<div >
< >

  <p className='color_of_textdm'>





  <a href="mailto:adam@ilumeth.org" className='color_of_textdm'> adam@ilumeth.org</a>













  </p>

 

  </>
</div>

 
</div>


     </Grid>
  </Grid>

  </div>
  )
}

export default MintComponent