import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import 'mdb-react-ui-kit/dist/css/mdb.min.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

// import { useEffect, useState } from 'react';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div> 
  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>

  </></div>
 
);

reportWebVitals();
