import React, { useState, useEffect } from "react";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Desktop from "./MintComponent";
import DesktopM from "./MintComponent";
import Desktopthree from "../Desktopthree";
import Desktoptwo from "../Desktoptwo";


import styled from 'styled-components';


const Home = () => {
  const [showModal, setShowModal] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const theme = useTheme();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    handleResize(); // Call once to initialize based on initial screen size

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleYesClick = () => {
    // Code to play music
    const audio = new Audio();
    audio.play();
  };

  const handleNoClick = () => {
    window.location.href = "";
  };

  return (
    <div className="">


<div className="">
      {/* THIS JAVASCRIPT CODE IS FOR Header of the Park Page*/}

      {/* <Slider /> */}
      {!smallScreen && (
        <div className="bg">
          <div className="pageCon">
        <Desktop/>
        <Desktopthree/>
        </div>
     </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Desktop View when you press on CustomSlider with ctrl and right arrow you move towards the Slider Page Desktop View*/}
      {smallScreen && (
         <div className="bg">
           <div className="pageCon">
               <Desktoptwo/>
         <DesktopM/>
         </div>

       </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Mobile View when you press on MobileSlider with ctrl and right arrow you move towards the Slider Page Mobile View*/}
    </div>
     
       {/*    <>  
    <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={() => {}}
        className="modalz"
      >
        <
        >
    
     
     
        <div className="sunken-panel w-full ">
        <>
        <h1 class="opacity-bg matrix-text text-4xl md:text-5xl mb-4 text-center token_text doyou" >Do you dare enter?</h1>
        </>
      
        <div className='centerall'>

        <button class='glowing-btn'   onClick={() => {
              handleYesClick();
              setShowModal(false);
            }}><span class='glowing-txt'>Y<span class='faulty-letter'>E</span>S</span></button>
      
        </div>
        <br/>
    </div>
        <>
        <div >        <  >
        
        </> </div>
        
        </>
   
  
        </>
  
      </Modal>
      
      </>
    THIS JAVASCRIPT CODE IS FOR the Slider used for the Mobile View when you press on MobileSlider with ctrl and right arrow you move towards the Slider Page Mobile View*/}

   
    </div>
  );
};

export default Home;
