import React from 'react'
import d from './dd.svg'
import u from './pinks.png'
import x from './tx.png'
import t from './telegr.png'
import ets from './ets.png'
import medi from './gitb.png'
function Desktopthree() {
  return (
    <div>
      
      <div class="sticky-icon">
   
    <a href="https://t.me/ilumeth" class="Youtube"><i class="fab"> <img src={t} style={{width:"25px"}}/></i> Telegram </a>
    <a href="https://twitter.com/ILUMeth" class="Twitter"><i class="fab "> <img src={x} style={{width:"30px"}}/></i> X </a>  
    <a href="https://etherscan.io/address/0x98e1f56b334438e3f0bde22d92f5bfd746e0631f" class="Twitter"><i class="fab "> <img src={ets} style={{width:"30px"}}/></i> Etherscan </a>  
    <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x90da5958f67857c30069301278fb7e5b70d74aa5" class="Instagram"><i class="fab "> <img src={d} style={{width:"20px"}}/>  </i> Dextool </a>
    <a href="https://ilum.gitbook.io/untitled/" class="Facebook"><i class="fab "> <img src={medi} style={{width:"30px"}}/></i> gitbook</a>
 </div></div>
  )
}

export default Desktopthree