import React from 'react'
import './Roadmap.css'
function Roadmap() {
  return (
    <div>

<ul className='uls '>
        <li style={{ '--accent-color': '#000' }}>

          <div className="date">Phase 1</div>
        
          <div className="descr">   <br/>
          - Social Media Marketing 
          <br/>
         - 4 Audits
      
        <br/>
       - Launch On Pinksale


          </div>

          
        </li>
        <li style={{ '--accent-color': '#000' }}>
          <div className="date">Phase 2</div>
          
          <div className="descr">

          <br/>
          - Illuminati Launch 
          <br/>
         - $3M+ Daily Volume
         <br/>
        - Tier 1 Callers
        <br/>
       - Trending + listing
      



          </div>
        </li>
        <li style={{ '--accent-color': '#000' }}>
          <div className="date">Phase 3</div>
         
          <div className="descr">

          <br/>
          - Tier 1 CEX Listing 
          <br/>
         - 10M+ Daily Volume
         <br/>
        - 50,000+ holders
        <br/>
       - Onboard retail investors
       

      



          </div>
        </li>
       
       
      </ul>



    </div>
  )
}

export default Roadmap